<template>
  <div
    class="
      d-flex
      align-items-center
      px-5
      ms-xl-4
      justify-content-center
    "
  >
    <form class="">
      <div class="text-center mb-4">
        <h3>Daftar Masuk</h3>
      </div>
      <div class="form-outline">
        <label class="form-label label-login" for="form2Example18"
          >Email atau No Pendaftaran Haji/PEKTA/Petugas</label
        >
        <input
          type="email"
          v-model="credential.identifier"
          class="form-control form-control-lg"
        />
        <p v-if="errors.identifier" class="text-danger error-text">Error msg</p>
      </div>

      <div class="form-outline mt-3">
        <label class="form-label label-login" for="form2Example28">Kata Laluan</label>
        <input
          type="password"
          v-model="credential.password"
          class="form-control form-control-lg"
          @keyup.enter="login()"
          autocomplete="off"
        />
        <p v-if="errors.password" class="text-danger error-text">Error msg</p>
      </div>

      <div class="text-end mt-3">
        <small>
          <!-- <router-link
            to="/auth/forgot-password"
            class="text-decoration-underline"
          >
            Lupa kata laluan?
          </router-link> -->
          <a
            href="/auth/forgot-password"
            class="text-decoration-underline"
          >
            Lupa kata laluan?
          </a>
        </small>
      </div>
      <div class="pt-3">
        <button
          class="btn btn-primary-2 btn-block w-100"
          type="button"
          @click="login()"
        >
          <div class="d-flex align-items-center" v-if="isProcessing">
            Loading...
            <div
              class="spinner-border ms-auto"
              role="status"
              aria-hidden="true"
            ></div>
          </div>
          <div v-else>Login</div>
        </button>
      </div>
      <div class="row mt-3">
        <div class="col">
          <a
            href="/auth/first-time"
            class="
              btn btn-outline-dark btn-block
              text-decoration-none
              lh-sm
              w-100
              h-100
            "
          >
            Login kali pertama?
          </a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import API from "./../../utils/API";
import Swal from "sweetalert2";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "login",
  data() {
    return {
      isProcessing: false,
      credential: {
        // identifier: "demo@inference.asia",
        // password: "demo123",
        // identifier: "admin@client.com",
        // password: "admin@client.com",
        identifier: null,
        password: null,
      },
      errors: {
        identifier: false,
        password: false,
      },
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    ...mapActions(["authLogin"]),
    login() {
      let errors = {
        identifier: this.credential.identifier == "" ? true : false,
        password: this.credential.password == "" ? true : false,
      };

      this.errors = errors;

      if (!this.errors.identifier && !this.errors.password) {
        this.isProcessing = true;
        this.authLogin(this.credential)
          .then((res) => {
            // temporary conditions
            // need to use JS window to refresh the vue router
            if (this.currentUser.role.name == "Administrator")
              window.location.href = "/admin/dashboard";
            else window.location.href = "/";

            this.isProcessing = false;
          })
          .catch((err) => {
            this.isProcessing = false;
            if (err.notconfirm) {
              Swal.fire({
                icon: "error",
                title: "Log masuk tidak berjaya",
                text: `Sila ke log masuk kali pertama.`,
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "No Pendaftaran dan Kata Laluan tidak sepadan",
                text: 'Sila cuba lagi atau hubungi pihak e-Bimbingan Haji untuk lebih lanjut.'
                // text: err,
              });
            }
          });
      }
    },
    toFirsttime() {},
  },
};
</script>

<style lang="scss" scoped>
@import "./../../assets/scss/color.scss";

form {
  width: 330px;

  h3 {
    color: $gray-dark;
  }

  span {
    color: $gray-reqular;
  }
  label {
    color: $gray-dark-03;

    &.label-login {
        font-size: 0.85rem!important; 

        @media (max-width: 439px) { 
          font-size: 0.65rem !important;
        }
    }
  }

  .error-text {
    margin-top: 4px;
    font-size: 12px;
  }

  a {
    color: $gray-light !important;
    text-decoration: unset;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>