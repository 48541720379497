<template>
  <!-- <div class="navbar-shadow bg-white"> -->
  <div id="topnav" class="">
    <div class="container-lg">
      <nav class="navbar navbar navbar-expand-lg navbar-light pt-3 pb-3">
        <div class="container-fluid">
          <router-link to="/" class="navbar-brand">
            <img src="./../assets/img/logo_1.png" />
          </router-link>
          <button
            class="navbar-toggler ms-auto border-0 order-1"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <ul class="mb-0 p-0 order-2 order-lg-3">
            <li class="nav-item d-block">
              <div v-if="currentUser" class="d-flex">
                <div
                  class="point-badge"
                  data-bs-toggle="modal"
                  data-bs-target="#pointDashboardModal"
                  @click="loadScoreboard()"
                >
                  <span><i class="fas fa-star"></i></span>
                  <span class="point-count">
                    {{ this.currentUserPoints ? this.currentUserPoints.attributes.points : 0 }}</span
                  >
                </div>
                <div class="dropdown">
                  <button
                    class="btn btn-white dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i
                      class="fas fa-circle text-danger badge-dot"
                      style="top: 0.75rem; left: 2.5rem"
                      v-if="
                        inboxItems.length > 0 &&
                        inboxItems.length > readInboxItems.length
                      "
                    />
                    <img
                      src="../assets/img/Sample_User_Icon.png"
                      alt="Avatar"
                      class="avatar"
                    />
                  </button>
                  <ul
                    class="dropdown-menu dropdown-menu-end"
                    aria-labelledby="dropdownMenuButton2"
                  >
                    <li>
                      <router-link
                        to="/user/profile/details"
                        class="dropdown-item"
                      >
                        Akaun Saya
                      </router-link>
                    </li>
                    <template
                      v-if="
                        currentUser &&
                        currentUser.role &&
                        currentUser.role.name == 'Administrator'
                      "
                    >
                      <li class="dropdown-divider"></li>
                      <li>
                        <router-link
                          to="/admin/dashboard"
                          class="dropdown-item"
                        >
                          Admin
                        </router-link>
                      </li>
                    </template>
                    <li class="dropdown-divider"></li>
                    <li>
                      <router-link
                        to="/user/inbox"
                        class="dropdown-item position-relative"
                      >
                        <i
                          class="fas fa-circle text-danger badge-dot"
                          style="top: 0.75rem; right: 1.25rem"
                          v-if="
                            inboxItems.length > 0 &&
                            inboxItems.length > readInboxItems.length
                          "
                        />
                        Peti Masuk
                      </router-link>
                    </li>
                    <li class="dropdown-divider"></li>
                    <li>
                      <div class="dropdown-item" @click="authLogout()">
                        Daftar Keluar
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="ms-3 d-none d-sm-none d-md-block">
                  <img src="./../assets/img/logo_ebimbingan.png" alt="" />
                </div>
              </div>
            </li>
          </ul>
          <div
            class="collapse navbar-collapse order-3 order-lg-2"
            id="navbarSupportedContent"
          >
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item me-3">
                <router-link
                  class="nav-link"
                  :class="{ active: isActiveNav('/') }"
                  aria-current="page"
                  to="/"
                  >Utama
                </router-link>
              </li>
              <li v-if="currentUser" class="nav-item me-3">
                <router-link
                  class="nav-link"
                  :class="{ active: isActiveNav('/user/courses') }"
                  aria-current="page"
                  to="/user/courses"
                  >Kursus</router-link
                >
              </li>
              <li v-else class="nav-item me-3">
                <router-link
                  class="nav-link"
                  :class="{ active: isActiveNav('/public-courses/courses') }"
                  aria-current="page"
                  to="/public-courses/courses"
                  >Kursus</router-link
                >
              </li>
              <li class="nav-item me-3">
                <router-link
                  class="nav-link"
                  :class="{ active: isActiveNav('/rujukan') }"
                  aria-current="page"
                  to="/rujukan"
                  >Rujukan</router-link
                >
              </li>
              <!-- <li class="nav-item me-3">
                <a
                  class="nav-link"
                  :class="{ active: isActiveNav('/tentang-kami') }"
                  aria-current="page"
                  href="/tentang-kami"
                  >Tentang Kami</a
                >
              </li> -->
              <li class="nav-item me-3">
                <router-link
                  class="nav-link"
                  :class="{ active: isActiveNav('/about-e-bimbingan') }"
                  aria-current="page"
                  to="/about-e-bimbingan"
                  >Tentang e-Bimbingan</router-link
                >
              </li>
              <li class="nav-item me-3">
                <router-link
                  class="nav-link"
                  :class="{ active: isActiveNav('/contact-us') }"
                  aria-current="page"
                  to="/contact-us"
                  >Hubungi Kami</router-link
                >
              </li>
              <li class="nav-item me-3" v-if="!currentUser">
                <a
                  class="nav-link"
                  aria-current="page"
                  :href="permohonanPektaLinks"
                  >Permohonan PEKTA</a
                >
              </li>
            </ul>
            <div class="d-flex" v-if="!currentUser">
              <!-- <button
                class="btn btn-primary"
                style="background-color:#309CB4;border-color:#309CB4"
                type="submit"
                @click="gotoBtn('/auth/login')"
              >
                Daftar Masuk
              </button> -->
              <button
                class="btn btn-primary"
                style="background-color:#309CB4;border-color:#309CB4"
                type="submit"
                data-bs-toggle="modal"
                data-bs-target="#popupLoginModal"
              >
                Daftar Masuk
              </button>
              <div class="ms-3">
                <img src="./../assets/img/logo_ebimbingan.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>

    <!-- Modal Sections -->
    <div
      class="modal fade"
      id="popupLoginModal"
      tabindex="-1"
      aria-labelledby="popupLoginModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body" style="">
            <login-form />
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="pointDashboardModal"
      tabindex="-1"
      aria-labelledby="pointDashboardModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body" style="">
            <div
              v-if="!isRankLoading"
              class="d-flex flex-column align-items-center point-dashboard"
            >
              <div class="d-flex flex-column align-items-center">
                <img
                  :src="currentUser && currentUser.profile_image ? currentUser.profile_image.url : 'https://tabunghaji.sgp1.digitaloceanspaces.com/user_profile_6bb83aeafc.png'"
                  alt=""
                  class="point-img-round rounded-circle"
                />
                <span
                  class="point-username mt-2"
                  >{{ currentUser && currentUser.full_name ? currentUser.full_name : '' }}</span
                >
              </div>
              <div
                class="d-flex point-stage position-relative justify-content-center"
              >
                <div
                  class="d-flex flex-column align-items-center stage-overlay"
                >
                  <div class="stage-point mb-1">
                    <span><i class="fas fa-star me-1"></i></span>
                    <span
                      >{{ this.currentUserPoints ? this.currentUserPoints.attributes.points : 0 }}
                      Mata</span
                    >
                  </div>
                  <div class="stage-score">
                    <span>Tempat : </span>
                    <span
                      class="scrore-val"
                      >{{ this.currentRank ? this.currentRank : '-' }}</span
                    >
                  </div>
                </div>
                <div class="stage-svg">
                  <svg
                    width="500"
                    height="120"
                    viewBox="0 0 640 154"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <rect
                      x="3.05176e-05"
                      y="104"
                      width="190"
                      height="50"
                      fill="#EDF5F6"
                    />
                    <rect
                      width="190"
                      height="20.0079"
                      transform="matrix(1 0 -0.646257 0.76312 12.9303 89)"
                      fill="#DCE6E9"
                    />
                    <rect
                      x="450"
                      y="104"
                      width="190"
                      height="50"
                      fill="#EDF5F6"
                    />
                    <rect
                      width="190"
                      height="20.0079"
                      transform="matrix(1 0 0.646257 0.76312 437 89)"
                      fill="#DCE6E9"
                    />
                    <rect
                      x="190"
                      y="24"
                      width="260"
                      height="130"
                      fill="#EAF3F4"
                    />
                    <rect
                      width="211.009"
                      height="30"
                      transform="matrix(1 0 -0.574291 0.818651 207.229 0)"
                      fill="#DCE6E9"
                    />
                    <rect
                      width="202.635"
                      height="30"
                      transform="matrix(1 0 0.574291 0.818651 230 0)"
                      fill="#DCE6E9"
                    />
                    <rect
                      x="300"
                      y="5"
                      width="40"
                      height="40"
                      fill="url(#pattern0)"
                    />
                    <defs>
                      <pattern
                        id="pattern0"
                        patternContentUnits="objectBoundingBox"
                        width="1"
                        height="1"
                      >
                        <use
                          xlink:href="#image0_2_14"
                          transform="scale(0.025)"
                        />
                      </pattern>
                      <image
                        id="image0_2_14"
                        width="40"
                        height="40"
                        xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIBSURBVHgB7ZldTsJQEIWnLSogBvAnRsODPvjuEmAFuiOWoKxAt+AKdAeyA3jCYEg0irQIbZ1TaEQELDMJXhO/ZCKpDXzMvT0DhWiKMAzLXBdcjXB13HNdcR3RPPifhbHYbwOHQuxlxXL855brlMygzlWxLOvZHh+okjlyAC5wIiscrXuDzKSCDlbJXM4haNLSTnOGJQ7JYGwynBQp8YOQOj2Pntw+vftBdCyTcii95tB+Lkvrjq4HqiWGVOu1F0nOwrEtlszQbjZNUsSC6FrrpZfo3FJ+k7YzGyRB1H8sZbvrJj7/IepyQBJEgu3u/GWdxWif9kmCSNAb+LQs2K8SRILucHnB+ApfFuNzUCQoyTZkowSRYFEQGek12UwQCSJ4EcJJiQNbgkgQL3iwlU18PuSkI088izEZfB5CjxzYi0bdIb+RonCKANVVvMdLvW7PfwrHslRyQB0zizJRmn2TqASTBLZWUiWYZB67gyFpUAl6w59fPFB+o1hBB5ef25OoPvK7Ex1EzpXyOd5zfhQ98d7zlR1UCQbcQWQdJsvndElFGYkPtChPuQdVgg5n4MlObuaUwPRABnbePNLw/71YCwSbZC51CN6QudSxB8s0unlpIsc238W84wc1Mo8auzX/xi1gPMABMqOTcKiMnb6DW8Jc1+Hop4FV0eC6HF8PX/gATR07tLqCobkAAAAASUVORK5CYII="
                      />
                    </defs>
                  </svg>
                </div>
              </div>
              <div class="d-flex point-scoreboard">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col" class="text-center">Tempat</th>
                      <th scope="col" style="width: 50%;">Nama</th>
                      <th scope="col" class="text-center">Markah Terkumpul</th>
                    </tr>
                  </thead>
                  <tbody v-if="this.usersRank.length > 0">
                    <tr v-for="(userRank, ind) in usersRank" :key="userRank.id">
                      <td class="text-center">
                        <svg
                          v-if="ind == 0"
                          width="12"
                          height="20"
                          viewBox="0 0 14 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
                            fill="#FFB700"
                          />
                          <path
                            d="M3.21 12.89L2 22L7 19L12 22L10.79 12.88"
                            fill="#FFB700"
                          />
                        </svg>
                        <svg
                          v-else-if="ind == 1"
                          width="12"
                          height="20"
                          viewBox="0 0 14 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
                            fill="#D1BE8E"
                          />
                          <path
                            d="M3.21 12.89L2 22L7 19L12 22L10.79 12.88"
                            fill="#D1BE8E"
                          />
                        </svg>
                        <svg
                          v-else-if="ind == 2"
                          width="12"
                          height="20"
                          viewBox="0 0 14 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
                            fill="#E5E0D2"
                          />
                          <path
                            d="M3.21 12.89L2 22L7 19L12 22L10.79 12.88"
                            fill="#E5E0D2"
                          />
                        </svg>
                        <svg
                          v-else
                          width="12"
                          height="20"
                          viewBox="0 0 14 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
                            fill="#D1E7ED"
                          />
                          <path
                            d="M3.21 12.89L2 22L7 19L12 22L10.79 12.88"
                            fill="#D1E7ED"
                          />
                        </svg>
                        {{ ind+1 }}
                      </td>
                      <td>
                        {{ userRank.user ? userRank.user.full_name : 'Anonymous' }}
                      </td>
                      <td class="score text-center">
                        <span>
                          <i
                            v-if="ind == 0"
                            class="fas fa-star me-1"
                            style="color: #ffb701;"
                          ></i>
                          <i
                            v-else-if="ind == 1"
                            class="fas fa-star me-1"
                            style="color: #d1be8e;"
                          ></i>
                          <i
                            v-else-if="ind == 2"
                            class="fas fa-star me-1"
                            style="color: #e5e0d2;"
                          ></i>
                          <i v-else class="fas fa-star me-1"></i>
                        </span>
                        {{ userRank.points }} Mata
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else>
              <div class="d-flex justify-content-center align-items-center">
                <span>Loading ...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../utils/API";
import { mapGetters, mapActions } from "vuex";
import LoginForm from './../components/main-auth/SimpleLogin.vue'

export default {
  data() {
    return {
      getInboxItemsEveryFiveMinutes: null,
      permohonanPektaLinks: `${process.env.VUE_APP_PEKTA_URL}/login`,
      usersRank: [],
      currentRank: null,
      isRankLoading: false
    };
  },
  components: {
    LoginForm
  },
  computed: {
    ...mapGetters(["currentUser", "currentUserPoints", "inboxItems", "readInboxItems"]),
  },
  watch: {
    currentUser: {
      handler() {
        if (this.currentUser) {
          this.getInboxItems();

          this.getReadInboxItems();

          if (!this.getInboxItemsEveryFiveMinutes) {
            this.getInboxItemsEveryFiveMinutes = setInterval(
              this.getInboxItems,
              300000
            );
          }
        } else {
          if (this.getInboxItemsEveryFiveMinutes) {
            clearInterval(this.getInboxItemsEveryFiveMinutes);

            this.getInboxItemsEveryFiveMinutes = null;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["authLogout", "getInboxItems", "getReadInboxItems"]),
    isActiveNav(name) {
      if (name !== "/") return this.$route.path.includes(name);
      return this.$route.path == name;
    },
    gotoBtn(_path) {
      this.$router.push({ path: _path });
    },
    loadScoreboard(){
      return new Promise(async(resolve, reject) => {
        this.isRankLoading = true

        if(this.currentUserPoints){
          let res01 = await API.get(`custom/user-points/${this.currentUserPoints.id}/rank`)
          this.currentRank = res01.data.userPoint.rank
        }
        if(this.currentUser) { //fetch scoreboard data
          let res02 = await API.get(`custom/user-points/scoreboard?displayCount=10`)
          this.usersRank = res02.data.userPoints
        }

        this.isRankLoading = false

        resolve(0)
      })
    }
  },
  async mounted(){
    // console.log('currentUserPoints : ', this.currentUserPoints)
    // console.log('currentUser : ', this.currentUser)
    await this.loadScoreboard()
  },
  beforeDestroy() {
    if (this.getInboxItemsEveryFiveMinutes) {
      clearInterval(this.getInboxItemsEveryFiveMinutes);

      this.getInboxItemsEveryFiveMinutes = null;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/color.scss";

#topnav {

  nav {
    a {
      color: #044C5C!important;
      font-weight: bold!important;

      @media (min-width: 992px) and (max-width: 1199.98px) {
        font-size: 10px;
       }

      @media (min-width: 1200px) and (max-width: 1399.98px) {
        font-size: 14px;
      }
    }

    .nav-link.active {
      font-weight: bold!important;
      color: #097d97!important
    }
  }

  .dropdown-item {
    &:active {
      background-color: white;
    }
  }
}

.badge-dot {
  font-size: 0.6em;
  position: absolute;
}

#popupLoginModal {
  .modal-dialog {
    .modal-content {
      .modal-body {
        padding: 50px 0px!important;
      }
    }
  }
}

.btn.btn-primary {
  @media (min-width: 992px) and (max-width: 1199.98px) {
    font-size: 11px;
  }

  @media (min-width: 1200px) and (max-width: 1399.98px) {
    font-size: 14px;
  }
}

.point-badge {
  border: 1px solid #00000063;
  border-radius: 10px;
  height: 40px;
  padding: 7px 15px;
  margin-right: 5px;
  margin-top: 10px;
  cursor: pointer;

  .fa-star {
    color: #F5BD43;
  }

  .point-count {
    color: #044C5C;
  }
}

.point-dashboard {
  padding: 25px 0px;

  .point-img-round {
    object-fit: cover;
    width: 100px;
    height: 100px;
  }

  .point-username {
    font-weight: 500;
    font-size: 15px;
    color: #000;
  }

  .point-stage {
    margin: 12px 0px;
    .stage-overlay {
      position: absolute;
      top: 45px;

      @media (max-width: 991px) {
        top: 23px;
      }

      .stage-point {
        font-size: 17px;
        color: #044C5C;

        @media (max-width: 991px) {
          font-size: 12px;
        }

        .fa-star {
          color: #F5BD43;
        }
      }

      .stage-score {
        color: #044C5C;
        font-size: 16px;

        @media (max-width: 991px) {
          font-size: 12px;
        }

        .scrore-val {
          background-color: #044C5C;
          border-radius: 5px;
          color: #fff;
          padding: 3px 10px 3px 11px;

          @media (max-width: 991px) {
            padding: 3px 3px 3px 6px;
          }
        }
      }
    }
    .stage-svg {
      svg {
        @media (max-width: 991px) {
          width: 300px;
          height: 72px;
        }
      }
    }
  }

  .point-scoreboard {
    table {
      width: 500px;

      @media (max-width: 991px) {
        width: 95%;
      }

      th {
        color: #BFD1D4;
        font-weight: 100;
        font-size: 12px;
      }

      td {
        color: #044C5C;
        font-weight: 400;
        padding: 12px 7px;

        @media (max-width: 991px) {
          font-size: 12px;
        }

        .fa-star {
          color: #BFD1D4;
        }

      }
    }
  }
}
</style>
